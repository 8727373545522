<template>
  <div class="width-100">
    <div
      v-if="loading || !resourceFromAPI"
      class="mt-4 text-center">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <div v-else>
      <v-toolbar
        v-if="$showFeature.groupResources && resourceGroupKeyName"
        flat
        class="dev-toolbar">
        <CyBtnToggle
          v-model="mode"
          :items="$static.modes"/>
        <v-autocomplete
          v-if="mode === 'groupResources'"
          v-model="selectedResourceGroups"
          :items="_.keys(sortedVersionsFromAPI).sort((a, b) => b - a)"
          :filter="(resourceGroupKey, query) => query && [
            getResourceValue(resourceGroupKey, 'title'),
            getResourceValue(resourceGroupKey, 'author'),
            resourceGroupKey,
          ].some((value) => _.toLower(value).includes( _.toLower(query)))"
          :menu-props="{ maxHeight: 600 }"
          :label="$t('selectedResources')"
          :placeholder="selectedResourcesPlaceholder"
          attach=""
          persistent-placeholder
          multiple
          small-chips
          deletable-chips
          hide-details
          clearable
          class="dev-toolbar__input">
          <template #item="{ item: resourceGroupKey }">
            <div
              :key="resourceGroupKey"
              class="dev-toolbar__item option">
              <strong class="option__id">
                {{ resourceGroupKey }}
                <a
                  class="option__link"
                  :disabled="!getResourceValue(resourceGroupKey, 'url')"
                  :href="$sanitizeUrl(getResourceValue(resourceGroupKey, 'url'))"
                  @click.stop>
                  <v-icon size="16">
                    launch
                  </v-icon>
                </a>
              </strong>
              <span class="option__name">{{ getResourceValue(resourceGroupKey, 'title') }}</span>
              <span class="option__author">{{ getResourceValue(resourceGroupKey, 'author') }}</span>
              <CyTag
                v-if="parseInt(getResourceValue(resourceGroupKey, 'approved_review_count', { targetKey: 'version' })) !== 0"
                class="option__tag"
                variant="success"
                small>
                {{ $t('approved') }}
              </CyTag>
            </div>
          </template>
        </v-autocomplete>
      </v-toolbar>

      <div class="pr-1">
        <v-row
          no-gutters
          align="center">
          <v-col class="pinned-version">
            <v-card
              v-if="resourceFromAPI.pinned_version"
              class="ma-4">
              <v-card-title
                v-has-rights-to="['UnpinResource', resourceCanonical]"
                class="d-flex flex-row flex-nowrap">
                <CyButton
                  theme="accent"
                  variant="tertiary"
                  icon-only
                  icon="fas fa-thumbtack"
                  class="mr-3"
                  @click="unpinResource"/>
                <div class="mr-4">
                  <div
                    v-for="(value, key, index) in resourceFromAPI.pinned_version"
                    :key="index"
                    class="text-left">
                    <span>{{ key }}: {{ value }}</span>
                  </div>
                </div>
                <div class="pinned-comment mr-3">
                  {{ resourceFromAPI.pin_comment }}
                </div>
                <CyButton
                  theme="accent"
                  variant="tertiary"
                  icon-only
                  icon="message"
                  @click="$toggle.showFormPinComment"/>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-slide-y-transition>
        <v-row
          v-if="showFormPinComment"
          class="mt-3">
          <v-col cols="11">
            <v-textarea
              v-model="formPinComment"
              v-has-rights-to="['ResourceSetPinComment', resourceCanonical]"
              name="pin-comment"
              :label="$t('pinComment')"
              outlined
              class="mt-2 pr-1"
              :value="resourceFromAPI.pin_comment ? resourceFromAPI.pin_comment : defaultPinComment"/>
          </v-col>
          <v-col
            cols="1"
            align-self="center">
            <div
              v-has-rights-to="['ResourceSetPinComment', resourceCanonical]"
              class="space-x-0 space-y-3">
              <CyButton
                theme="success"
                :disabled="saveCommentLoading || loading"
                icon="done"
                block
                @click="commentPinnedVersion()">
                {{ $t('forms.btnSave') }}
              </CyButton>
              <CyButton
                variant="secondary"
                block
                @click="cancelComment()">
                {{ $t('forms.btnCancel') }}
              </CyButton>
            </div>
          </v-col>
        </v-row>
      </v-slide-y-transition>

      <div class="pl-4 mt-2 pr-1">
        <v-card>
          <div class="d-flex align-center">
            <CyTooltip right>
              <template #activator="{ on }">
                <span
                  class="header-icon"
                  v-on="on">
                  <CyButton
                    v-has-rights-to="['GetResource', resourceCanonical]"
                    :loading="btnRefreshLoading"
                    icon-only
                    icon="sync"
                    @click="refreshResource(true)"/>
                </span>
              </template>

              <span>{{ $t('forms.btnRefresh') }}</span>
            </CyTooltip>

            <div class="ml-2 flex-grow-1">
              <span v-if="btnRefreshLoading">
                {{ $t('checking') }}...
              </span>
              <span
                v-else-if="resourceError"
                class="failing-to-check">
                {{ $t('checkingFailed') }}
              </span>
              <span v-else>
                {{ $t('successfullyChecking') }}
              </span>
            </div>

            <div class="text-right mr-3">
              <v-icon
                v-if="resourceError"
                color="error">
                fa-times-circle
              </v-icon>
              <v-icon
                v-else
                color="success">
                done
              </v-icon>
            </div>
          </div>
          <v-card-text
            v-if="resourceError"
            class="content">
            <CyViewsBuildLeaf
              v-if="resourceError"
              :resources="resourceFromAPI"
              :item="resourceFromAPI.build.plan"
              :content="concourseContent"
              hide-header/>
          </v-card-text>
        </v-card>
      </div>

      <v-scroll-x-transition>
        <div
          v-show="!showFormPinComment"
          class="pl-4 pr-1">
          <template v-if="!$showFeature.groupResources || mode === 'default'">
            <CyViewsResourceVersion
              v-for="(item, key, index) of versionsFromAPI"
              :key="index"
              v-has-rights-to="['GetResourceVersions', resourceCanonical]"
              :version="item"
              :pipeline-canonical="pipelineCanonical"
              :resource-canonical="resourceCanonical"
              :project-canonical="projectCanonical"
              :resource="resourceFromAPI"
              @comment-pinned="commentPinnedVersion"/>
          </template>
          <template v-else>
            <div
              v-show="isResourceGroupSelected(resourceGroupKey)"
              v-for="[resourceGroupKey, versions] of _(sortedVersionsFromAPI).entries().orderBy(0, ['desc'])"
              :key="resourceGroupKey">
              <h2 class="mt-8">
                {{ resourceGroupKey }}
              </h2>
              <CyViewsResourceVersion
                v-for="version of versions"
                :key="version.id"
                v-has-rights-to="['GetResourceVersions', resourceCanonical]"
                :version="version"
                :pipeline-canonical="pipelineCanonical"
                :resource-canonical="resourceCanonical"
                :project-canonical="projectCanonical"
                :resource="resourceFromAPI"
                @comment-pinned="commentPinnedVersion"/>
            </div>
          </template>
        </div>
      </v-scroll-x-transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyViewsBuildLeaf from '@/components/views/build-leaf.vue'
import CyViewsResourceVersion from '@/components/views/resource-version.vue'
import concourseMixin from '@/mixins/concourse'
import { constructBreadcrumb, mdiToSVG } from '@/utils/helpers'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import 'moment-duration-format'

export const REFRESH_INTERVAL_TIMER = 1000
export const REFRESH_CHECK_RESOURCE = 5000

export default {
  name: 'CyPageResource',
  components: {
    CyViewsResourceVersion,
    CyViewsBuildLeaf,
    CyBtnToggle: () => import('@/components/btn-toggle.vue'),
  },
  mixins: [concourseMixin],
  breadcrumb () {
    const { pipelineCanonical, projectCanonical, projectName, resourceCanonical } = this
    return constructBreadcrumb(this.$options.name, resourceCanonical, [
      {
        label: this.$t('routes.resource'),
      },
      {
        name: 'pipeline',
        params: { pipelineCanonical },
      },
      {
        label: this.$t('routes.pipeline'),
        name: 'pipeline',
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  header () {
    if (this.loading) return {}
    const { name, type, last_checked: lastChecked, icon } = this.resourceFromAPI || {}
    return {
      icon: icon ? this.resourceIcon : null,
      title: name,
      metas: [
        type,
        lastChecked ? this.$t('checked', [$date.$formatTimeAgo(lastChecked)]) : this.$t('notChecked'),
      ],
    }
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    pipelineCanonical: {
      type: String,
      default: '',
    },
    resourceCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    btnRefreshLoading: false,
    dateNow: null,
    formPinComment: null,
    loading: false,
    mode: 'default',
    refreshIntervalID: null,
    resourceFromAPI: null,
    saveCommentLoading: false,
    selectedResourceGroups: [],
    showFormPinComment: false,
    versionsFromAPI: null,
  }),
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
    }),
    $static () {
      return {
        modes: [
          {
            key: 'default',
            value: 'default',
            text: this.$t('Default'),
          },
          {
            key: 'groupResources',
            value: 'groupResources',
            text: this.$t('groupBy', { key: this.resourceGroupKeyName }),
          },
        ],
      }
    },
    resourceError () {
      const originId = _.get(this.resourceFromAPI, 'build.plan.id')
      return _.get(this.concourseContent, `${originId}.error`) || _.get(this.concourseContent, `${originId}.resource.exitStatus`)
    },
    /*
     * This function is also implemented on the builds page.
     * It allows to get the build id of a different endpoint, but using the same mixin
     */
    // eslint-disable-next-line vue/no-unused-properties
    buildId () {
      return _.get(this.resourceFromAPI, 'build.id', null)
    },
    defaultPinComment () {
      return `pinned by ${this.profile.given_name} ${this.profile.family_name} at ${moment(this.dateNow * 1000).format('LLL')}` // eslint-disable-line you-dont-need-momentjs/format
    },
    resourceIcon () {
      return mdiToSVG(this.resourceFromAPI.icon, this.resourceFromAPI.name)
    },
    resourceGroupKeyName () {
      if (_.some(this.versionsFromAPI, 'version.pr')) return 'version.pr'
      return ''
    },
    selectedResourcesPlaceholder () {
      if (!this.resourceGroupKeyName) return ''
      const searchBy = this.$t('forms.searchBy')
      const labels = ['title', 'author', this.resourceGroupKeyName]
      const listOfLabels = _.$getListFromArray(labels, { conjunction: this.$t('or') })
      return `${searchBy} ${listOfLabels}`
    },
    sortedVersionsFromAPI () {
      const versionsFromAPI = _.cloneDeep(this.versionsFromAPI)
      return !this.resourceGroupKeyName
        ? {}
        : _(versionsFromAPI)
          .map((resource) => {
            return resource?.version?.committed
              // eslint-disable-next-line you-dont-need-momentjs/format
              ? _.set(resource, 'version.committed', moment(resource.version.committed).format('ddd DD MMMM YYYY - HH:MM'))
              : resource
          })
          .groupBy(this.resourceGroupKeyName)
          .value()
    },
  },
  beforeDestroy () {
    window.clearInterval(this.refreshIntervalID)
  },
  async mounted () {
    window.clearInterval(this.refreshIntervalTimerID)
    this.refreshIntervalTimerID = window.setInterval(() => {
      this.dateNow = Date.now() / REFRESH_INTERVAL_TIMER
    }, REFRESH_INTERVAL_TIMER)

    await this.refreshResource()
  },
  methods: {
    async unpinResource () {
      await this.$cycloid.ydAPI.unpinResource(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical)
      await this.refreshResource()
    },
    cancelComment () {
      this.formPinComment = this.resourceFromAPI.pin_comment
      this.showFormPinComment = false
    },
    async commentPinnedVersion () {
      if (this.showFormPinComment) {
        this.saveCommentLoading = true
        await this.$cycloid.ydAPI.resourceSetPinComment(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical, this.formPinComment)
        this.showFormPinComment = false
        this.saveCommentLoading = false
      } else {
        await this.$cycloid.ydAPI.resourceSetPinComment(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical, this.defaultPinComment)
      }
      await this.refreshResource()
    },
    async refreshResource (check = false) {
      this.btnRefreshLoading = true

      if (check) await this.checkResource()
      await this.getPipelineResourceFromAPI()
      await this.getResourceVersionsFromAPI()

      this.btnRefreshLoading = false
    },
    async getResourceVersionsFromAPI () {
      const { data } = await this.$cycloid.ydAPI.getResourceVersions(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical) || {}
      if (data) this.versionsFromAPI = data
    },
    async checkResource () {
      await this.$cycloid.ydAPI.checkResource(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical)
      await _.$pause(REFRESH_CHECK_RESOURCE)
    },
    async getPipelineResourceFromAPI () {
      if (!this.resourceFromAPI) this.loading = true
      const { data } = await this.$cycloid.ydAPI.getPipelineResource(this.orgCanonical, this.projectCanonical, this.pipelineCanonical, this.resourceCanonical) || {}
      if (data) {
        this.resourceFromAPI = data
        if (this.resourceFromAPI.pin_comment) this.formPinComment = this.resourceFromAPI.pin_comment
      }
      this.setupStream()

      this.loading = false
    },
    isResourceGroupSelected (resourceGroupKey) {
      return _.some([
        _.isEmpty(this.selectedResourceGroups),
        this.selectedResourceGroups.includes(resourceGroupKey),
      ])
    },
    getResourceValue (resourceGroupKey, fieldName, { targetKey = 'metadata' } = {}) {
      const resources = this.sortedVersionsFromAPI?.[resourceGroupKey]
      const resource = _.chain(resources)
        .map(targetKey)
        .flatten()
        .find((resource) => targetKey === 'metadata' ? resource?.name === fieldName : _.has(resource, fieldName))
        .value() || {}
      return targetKey === 'metadata' ? resource?.value : resource?.[fieldName]
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.resource',
        approved: 'Approved',
        btnBackToPipeline: 'Back to the pipeline',
        checking: 'Checking',
        checkingFailed: 'Check failed',
        checked: 'Checked {0}',
        groupBy: 'Group by <code>{key}</code>',
        notChecked: 'Not checked yet',
        pinComment: 'Pin comment',
        selectedResources: 'Selected resources',
        successfullyChecking: 'Successfully checked',
      },
      es: {
        title: '@:routes.resource',
        approved: 'Aprobado',
        btnBackToPipeline: 'De vuelta a la pipeline',
        checking: 'Actualización en progreso',
        checked: 'Chequeado {0}',
        groupBy: 'Agrupar por <code>{key}</code>',
        notChecked: 'Aún no verificado',
        checkingFailed: 'Error durante la actualización',
        pinComment: 'Fijar comentario',
        selectedResources: 'Recursos seleccionados',
        successfullyChecking: 'Actualizado con éxito',
      },
      fr: {
        title: '@:routes.resource',
        approved: 'Approuvé',
        btnBackToPipeline: 'Revenir à la pipeline',
        checking: 'Rafraîchissement en cours',
        checked: 'Vérifié {0}',
        groupBy: 'Regrouper par <code>{key}</code>',
        notChecked: 'Pas encore vérifiée',
        checkingFailed: 'Erreur lors du rafraîchissement',
        pinComment: 'Poster un commentaire',
        selectedResources: 'Ressources sélectionnées',
        successfullyChecking: 'Rafraîchis avec succès',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  pre {
    font-size: 12px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .content {
    padding: 0 !important;
  }

  .resource-name {
    font-size: 18px;
    font-weight: bold;
  }

  .resource-icon {
    vertical-align: middle;
  }

  .header-icon {
    margin: 8px 8px 8px -18px;
  }

  .float-right {
    float: right;
  }

  .v-card {
    border: 1px solid cy-get-color("primary", $alpha: 0.2);
    border-radius: 5px;
    box-shadow: 2px 3px 8px -6px cy-get-color("primary");
    font-family: $font-family-code;
    font-size: $font-size-sm;
  }

  .v-card__title {
    padding: 8px;
    font-size: $font-size-sm;
    font-weight: $font-weight-default;
    line-height: 1.5;
  }

  .failing-to-check {
    color: red;
    font-weight: bold;
  }

  .pinned-version {
    flex-basis: auto;
    flex-grow: 0;
    width: auto;

    .v-card {
      border-color: cy-get-color("accent") !important;
      word-break: break-word;
    }
  }

  .pinned-message {
    &:hover {
      color: cy-get-color("secondary");
    }

    &:active {
      color: cy-get-color("primary");
    }

    color: cy-get-color("accent");
  }

  .pinned-comment {
    color: cy-get-color("accent");
  }

  .pin-comment-form ::v-deep .v-input__slot {
    border: 1px solid cy-get-color("primary") !important;
  }

  .dev-toolbar {
    display: flex;
    z-index: 1;
    justify-content: stretch;
    width: 100%;
    height: auto !important;
    margin-block: 16px;

    &:is(.v-toolbar, .theme--light, .v-sheet) {
      background: none;
    }

    ::v-deep {
      .v-toolbar__content {
        flex: 1 1 100%;
        align-items: start;
        height: auto !important;
        padding-right: 4px;
      }

      .v-select__slot .v-select__selections {
        min-height: 32px;
      }
    }

    &__input {
      flex: 1 1 auto;
      margin-left: 0.5em;
    }

    .option {
      display: grid;
      grid-template-areas:
        "id name   tag"
        "id author tag";
      grid-template-columns: auto 1fr auto;
      gap: 0 16px;
      width: calc(100% - 24px);

      &__id {
        grid-area: id;
        font-size: $font-size-xl;
        font-weight: $font-weight-medium;
      }

      &__name {
        grid-area: name;
        color: cy-get-color("primary");
        font-size: $font-size-sm;
      }

      &__author {
        grid-area: author;
        color: cy-get-color("primary", "light-2");
        font-size: $font-size-xs;
      }

      &__tag {
        grid-area: tag;
        align-self: center;
      }

      &__link {
        color: cy-get-color("secondary");

        .v-icon {
          padding: 2px;
          border-radius: 4px;
          color: currentColor;
        }

        &:is([disabled]) {
          color: cy-get-color("grey", "dark-1");
        }

        &:not([disabled]):is(:hover, :focus) .v-icon {
          background: cy-get-color("secondary");
          color: cy-get-color("white");
        }
      }
    }
  }
</style>
